<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="
          $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : 'txt-left'
        "
      >
        {{ $t("app.stars") }}
      </h4>
      <div class="col-12 pd-0" style="padding: 0">
        <select
          id="star"
          class="form-control text-lowercase"
          v-model="row.star"
          v-on:change="onStarChange"
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  namme: "CardStar",
  props: ["star"],
  data() {
    return {
      row: {
        star: this.$props.star,
      },
    };
  },
  methods: {
    onStarChange() {
      const data = {
        star: this.row.star,
      };
      this.$emit("starChange", data);
    },
  },
};
</script>
